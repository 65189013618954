import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://4ba37429887e8f3249ba65ea2def439b@o4508205656702976.ingest.de.sentry.io/4508205658275920",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});